
import { parse, startOfMonth, endOfMonth, format, sub, subYears } from 'date-fns';

export type Period = 'alltime' | 'year' | 'month' | 'week' | 'yesterday' | 'today' | 'custom'
type DateMap = Record<Period, string>


import type { App } from '~~/types'


const monthMap: Record<string, [string, string]> = {
    'jan': ['01', 'januari'],
    'feb': ['02', 'februari'],
    'maa': ['03', 'maart'],
    'apr': ['04', 'april'],
    'mei': ['05', 'mei'],
    'jun': ['06', 'juni'],
    'jul': ['07', 'juli'],
    'aug': ['08', 'augustus'],
    'sep': ['09', 'september'],
    'okt': ['10', 'oktober'],
    'nov': ['11', 'november'],
    'dec': ['12', 'december']
}


export const useStatsQuery = () => {
    const dateMap: DateMap = {
        'alltime': 'Alle gegevens',
        'year': 'Afgelopen jaar',
        'month': 'Afgelopen maand',
        'week': "Afgelopen week",
        'yesterday': "Gisteren",
        'today': "Vandaag",
        'custom': 'Custom range'
    } as const

    

    const dateRange: Ref<{
        start: Date,
        end: Date
    }> = useState('query', () => ({ start: subYears(new Date(), 1), end: new Date() }))

    const getDateRangeParam = () => {
        return `${format(dateRange.value.start, 'yyyy-MM-dd')},${format(dateRange.value.end, 'yyyy-MM-dd')}`
    }


    function getDateRangeForMonth(month: string, year: string) {
        const monthData = monthMap[month.toLowerCase()];
        
        if (!monthData) {
            throw new Error(`Invalid month: ${month}`);
        }
    
        const [numericMonth, fullMonthName] = monthData;
    
        // Create a date string in the format "yyyy-MM-dd"
        const dateString = `${year}-${numericMonth}-01`;
    
        // Parse the date
        const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    
        // Get the first and last day of the month
        const startDate = startOfMonth(parsedDate);
        const endDate = endOfMonth(parsedDate);
        const fullYear = format(startDate, 'yyyy');
    
        return { 
            startDate, 
            endDate,
            fullMonthName ,
            fullYear
        };
    }


    const report: Ref<{
        month: string,
        year: string
    }> = useState('report', () => ({ 
        month: '',
        year: ''
     }))

    


    const setReport = () => {
        const route = useRoute()
        const val = route.query.rapport?.toString() || route.query.report?.toString()
        let [month, year] = val?.split('_') as [string, string]
    
        if (!!month && !!monthMap[month] && !!year && !isNaN(parseInt(year))) {
            const { 
                startDate, 
                endDate,
                fullMonthName,
                fullYear
            } = getDateRangeForMonth(month, year)

            if (startDate && endDate) {
                dateRange.value.start = startDate
                dateRange.value.end = endDate
                report.value.month = fullMonthName
                report.value.year = fullYear
            }
        }
    }


    const clearReport = () => {
        report.value.month = ''
        report.value.year = ''
    }

    // TODO should add interface component so users can choose!
    const getGroupByParam = () => {
        if(report.value.month) return 'day'
        return undefined
    }


    const compareBy: Ref<'none' | 'previous_period' | 'year_over_year'> = useState('compare_by', () => 'previous_period')
    

    return { dateMap, getDateRangeParam, dateRange, compareBy, report, setReport, clearReport, getGroupByParam }
}