<template>
   <UPopover mode="hover" class="flex items-center" :class="isInfinity ? 'hidden' : ''"> 
      <USkeleton v-if="loading" class="h-4 w-8" />
      <UBadge v-else :color="trendColor" size="2xs" variant="soft" class="font-bold">{{ (trend !== '∞' ? (trend < 0 ? '' : '+') : '') + trend }}{{isAbsoluteNumber ? '' : '%'}}</UBadge>
    
    <template #panel>
      <p class="p-4  max-w-xs sm:max-w-md  text-2xs leading-loose font-normal">
        Trends geven inzicht in de (procentuele) stijging of daling ten opzichte van {{ compareBy === 'previous_period' ? 'de vorige periode' : 'dezelfde periode in het voorgaande jaar' }}
      </p>
    </template>
   </UPopover>
  
</template>

<script lang="ts" setup>
const props = defineProps<{
  count?: number | 'infinite'
  loading?: boolean
  isAbsoluteNumber?: boolean
}>()

const { compareBy } = useStatsQuery()
const trend = computed(() => props.count === 'infinite' ? '∞' : Math.ceil(props.count || 0))

const isInfinity = computed(() => trend.value === '∞')

const trendColor = computed(() => isInfinity.value ? 'success' : typeof trend.value !== 'number' ? 'gray' : trend.value < 0 ? 'error' : trend.value > 0 ? 'success' : 'gray')
</script>