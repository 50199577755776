/**
 * Capitalizes the first character of a given string.
 *
 * @param str - The string to capitalize.
 * @returns A new string with the first character capitalized. If the input string is empty, the function returns the original empty string.
 *
 * @example
 * ```typescript
 * capitalizeFirstChar("hello"); // Returns "Hello"
 * capitalizeFirstChar("typescript"); // Returns "Typescript"
 * capitalizeFirstChar(""); // Returns ""
 * ```
 */
export function capitalizeFirstChar(str: string): string {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}



/**
 * Formats a number with commas as thousand separators in `nl-NL` locale.
 * If the number is in millions, it appends 'M' with one decimal place (e.g., 10,5M).
 * If the number is in billions, it appends 'B' with one decimal place (e.g., 1,5B).
 * 
 * @param num - The number to be formatted.
 * @returns A string representing the formatted number.
 *
 * @example
 * ```ts
 * formatNumber(1050000);     // "1,1M"
 * formatNumber(1500000000);  // "1,5B"
 * formatNumber(123456);      // "123.456"
 * ```
 */
export function formatNumber(num: number): string {
  if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1).replace('.', ',') + 'B';
  } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1).replace('.', ',') + 'M';
  } else {
      return num.toLocaleString('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }
}



/**
 * Returns the first N characters of a given string.
 *
 * @param str - The string to extract characters from.
 * @param n - The number of characters to take from the start of the string.
 * @returns The first N characters of the string. If N is greater than the length of the string, the full string is returned.
 *
 * @example
 * ```typescript
 * const result = takeFirstNCharacters("Hello, World!", 5);
 * console.log(result); // "Hello"
 * ```
 */
export function takeFirstNCharacters(str: string | null, n: number): string {
  if (n <= 0 || !str) {
    return ""; // If n is less than or equal to 0, return an empty string
  }
  return str.slice(0, n);
}


/**
* Validates an email address for RFC 5322 compliance.
* 
* This function checks if the provided email is syntactically valid
* according to the RFC 5322 standard. It also ensures that the length
* does not exceed 254 characters, as per common email length limits.
*
* @param email - The email address to validate.
* @returns `true` if the email is valid and `false` otherwise.
*/
export const validateEmail = (email: string): boolean => {
// RFC 5322 compliant email regex
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
return emailRegex.test(email) && email.length <= 254;
};