<template>
  <DashboardGrid gap="sm">
    <Stat
      v-for="type in contentTypes"
      :title="type.name"
      :hint="type.description"
      :count="type.count"
      :trend="type.growth"
      class="md:col-span-4"
      :icon="type.icon"
      :loading="loading"
      :error="error"
      is-absolute-trend
    />
  </DashboardGrid>
</template>

<script lang="ts" setup>

const props = defineProps<{
  loading: boolean,
  error?: any
  data?: Record<string, {
    growth: number | null,
    count: number,
    url?: string
    description: string
  }> | null
}>()



const getIcon = (name: string) => {
  if (name === 'artikelen') return 'i-heroicons-document-text-20-solid'
  if (name === 'auteurs') return 'i-heroicons-user-group-20-solid'
  if (name === 'veelgestelde vragen') return 'i-heroicons-information-circle-20-solid'
  if (name === "thema's") return 'i-heroicons-adjustments-vertical-20-solid'
  if (name === 'medewerkers') return 'i-heroicons-users-20-solid'
  if (name === 'activiteiten') return 'i-heroicons-calendar-date-range-20-solid'
  if (name === 'vacatures') return 'i-heroicons-briefcase-20-solid'
  if (name === 'onderwijsinstellingen') return 'i-heroicons-building-office-2-20-solid'
  if (name === 'opleidingen') return 'i-heroicons-academic-cap-20-solid'
  if (name === 'regioloketten') return 'i-heroicons-building-storefront-20-solid'
  if (name === 'routes') return 'i-heroicons-arrow-trending-up-20-solid'
  if (name === 'podcasts') return 'i-heroicons-microphone-20-solid'
  if (name === 'videos') return 'i-heroicons-play-20-solid'
  if (name === 'scholen') return 'i-heroicons-building-library-20-solid'

  return 'i-heroicons-document-text-20-solid'
}



const contentTypes = computed(() => {
  if (!props.data) return Array(6).fill({
      name: '',
      description: '',
      count: 0,
      growth: 0,
      icon: '',
      url: undefined,
  });
  return Object.entries(props.data).map(([key, value]) => {
    return {
      name: capitalizeFirstChar(key),
      description: value.description,
      count: value.count,
      growth: value.growth,
      icon: getIcon(key),
      url: value.url
    }
  })
})

</script>

<style>

</style>