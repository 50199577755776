<template>
  <IconCard
    :ui="{
      background: 'bg-white dark:bg-gray-700/50',
      ring: !!error ? 'ring-1 ring-error-500 dark:ring-error-500' : 'ring-1 ring-primary-100 dark:ring-gray-600',
      header: {
        padding: 'pt-5 pb-5 xl:px-4'
      }
    }"
    :icon="icon"
    color="primary"
    size="lg"
  >
    <template #header>
      <div class="flex gap-4">
      <div class="grow">
        
        <USkeleton v-if="loading && !title" class="h-4 w-1/2"/>
        <div v-else-if="$slots.title">
          <slot name="title" />
        </div>  
        <h6 v-else class="text-3xs md:text-2xs mb-0" :class="[size === 'sm' ? 'md:leading-snug' : '', error ? 'text-error-500' : 'text-gray-600 dark:text-gray-400']">{{ title }}</h6>
        <div class="flex gap-2 items-center flex-wrap">
          <USkeleton v-if="loading" class="h-5 w-12 mt-2"/>
          <span v-else-if="$slots.count && !error">
            <slot name="count" />
          </span>  
          <span v-else-if="!error" class="text-xl xl:text-lg font-bold" :class="size === 'sm' ? 'md:leading-4 md:mt-2' : ''">{{ c + (percentage ? '%' : '') }}</span>
          
          <Trend 
            v-if="loading || typeof trend === 'number'"
            :count="trend"
            :class="loading ? 'mt-2' : size === 'sm' ? 'md:mt-2' : ''"
            :loading="loading"
            :is-absolute-number="isAbsoluteTrend"
          />
        </div>
      </div>
      <div v-if="hint" class="self-start relative -top-1.5">
        <Hint :text="hint"/>
      </div>
    </div>
    </template>
    
  </IconCard>
  
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  title?: string,
  trend?: number | 'infinite'
  count?: number
  icon?: string
  hint?: string
  loading: boolean
  percentage?: boolean
  size?: 'sm'
  error?: any
  formatCount?: boolean
  isAbsoluteTrend?: boolean
}>(), {
  icon: 'i-heroicons-document-text',
  formatCount: true
})

const c = computed(() => !props.percentage && props.formatCount ? formatNumber(props.count || 0) : typeof props.count === 'number' && !isNaN(props.count) ? props.count : 0)
</script>